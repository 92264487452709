@use '@ag-grid-community/styles' as ag;

@import url('@adaptabletools/adaptable-angular-aggrid/index.css') layer(adpatable);

@import url('highcharts/css/highcharts.css') layer(highcharts);

@import url('assets/styles/themes/lumatrak-light/theme.scss') layer(primeng);
@import url('primeng/resources/primeng.min.css') layer(primeng);
@import url('primeflex/primeflex.scss') layer(primeng);

@layer highcharts {
  @import 'assets/styles/themes/lumatrak-light/colors.scss';

  @function luminance($color) {
    $r: red($color) / 255;
    $g: green($color) / 255;
    $b: blue($color) / 255;

    @each $val in ($r, $g, $b) {
      @if $val < 0.03928 {
        $val: $val / 12.92;
      } @else {
        $val: pow(($val + 0.055) / 1.055, 2.4);
      }
    }

    @return (0.2126 * $r) + (0.7152 * $g) + (0.0722 * $b);
  }

  @function text-color($background) {
    $luminance: luminance($background);

    @if $luminance > 0.5 {
      @return #333333; // Dark gray for light backgrounds
    } @else {
      @return #f0f0f0; // Light gray for dark backgrounds
    }
  }

  .highcharts-label-box {
    fill: var(--blueGray-800);
  }

  /** Make the background of the tooltip white */
  g.custom-tooltip > path {
    fill: #ffffff !important;
  }

  //DEFAULT COLORS

  //Color1
  .highcharts-color-1 {
    fill: var(--sky-600);
  }

  .highcharts-data-label-color-1 .pieLabelStyle {
    color: text-color($sky-600) !important;
  }

  //Color2
  .highcharts-color-2 {
    fill: var(--violet-600);
  }

  .highcharts-data-label-color-2 .pieLabelStyle {
    color: text-color($violet-600) !important;
  }

  //Color3
  .highcharts-color-3 {
    fill: var(--teal-500);
  }

  .highcharts-data-label-color-3 .pieLabelStyle {
    color: text-color($teal-600) !important;
  }

  //Color4
  .highcharts-color-4 {
    fill: var(--brown-500);
  }

  .highcharts-data-label-color-4 .pieLabelStyle {
    color: text-color($brown-500) !important;
  }

  //Color5
  .highcharts-color-5 {
    fill: var(--indigo-400);
  }

  .highcharts-data-label-color-5 .pieLabelStyle {
    color: text-color($indigo-400) !important;
  }

  //Color6
  .highcharts-color-6 {
    fill: var(--fuchsia-500);
  }

  .highcharts-data-label-color-6 .pieLabelStyle {
    color: text-color($fuchsia-500) !important;
  }

  //Color7
  .highcharts-color-7 {
    fill: var(--cyan-400);
  }

  .highcharts-data-label-color-7 .pieLabelStyle {
    color: text-color($cyan-400) !important;
  }

  //Color8
  .highcharts-color-8 {
    fill: var(--slate-500);
  }

  .highcharts-data-label-color-8 .pieLabelStyle {
    color: text-color($slate-500) !important;
  }

  //Color9
  .highcharts-color-9 {
    fill: var(--purple-400);
  }

  .highcharts-data-label-color-9 .pieLabelStyle {
    color: text-color($purple-400) !important;
  }

  //Color10
  .highcharts-color-10 {
    fill: var(--yellow-500);
  }

  .highcharts-data-label-color-10 .pieLabelStyle {
    color: text-color($yellow-500) !important;
  }

  //Color11
  .highcharts-color-11 {
    fill: var(--stone-500);
  }

  .highcharts-data-label-color-11 .pieLabelStyle {
    color: text-color($stone-500) !important;
  }

  //STATUS COLORS

  //Expired
  .highcharts-color-30 {
    fill: var(--red-500);
  }

  .highcharts-data-label-color-30 .pieLabelStyle {
    color: text-color($red-500) !important;
  }

  //Returned
  .highcharts-color-31 {
    fill: var(--orange-500);
  }

  .highcharts-data-label-color-31 .pieLabelStyle {
    color: text-color($orange-500) !important;
  }

  //Restocked
  .highcharts-color-32 {
    fill: var(--brown-500);
  }

  .highcharts-data-label-color-32 .pieLabelStyle {
    color: text-color($brown-500) !important;
  }

  //Recalled
  .highcharts-color-33 {
    fill: var(--orange-500);
  }

  .highcharts-data-label-color-33 .pieLabelStyle {
    color: text-color($orange-500) !important;
  }

  //Relocated
  .highcharts-color-34 {
    fill: var(--yellow-500);
  }

  .highcharts-data-label-color-34 .pieLabelStyle {
    color: text-color($yellow-500) !important;
  }

  //Relocate - At Risk
  .highcharts-color-35 {
    fill: var(--red-600);
  }

  .highcharts-data-label-color-35 .pieLabelStyle {
    color: text-color($red-600) !important;
  }

  .highcharts-color-36 {
    fill: var(--green-500);
  }

  .highcharts-data-label-color-36 .pieLabelStyle {
    color: text-color($green-500) !important;
  }

  .highcharts-color-38 {
    fill: var(--green-500);
  }

  .highcharts-data-label-color-38 .pieLabelStyle {
    color: text-color($green-500) !important;
  }

  .highcharts-color-39 {
    fill: var(--green-700);
  }

  .highcharts-data-label-color-39 .pieLabelStyle {
    color: text-color($green-700) !important;
  }

  //REGION COLORS

  //Southwest
  .highcharts-color-50 {
    fill: var(--brown-500);
  }

  .highcharts-data-label-color-50 .pieLabelStyle {
    color: text-color($brown-500) !important;
  }

  //Southeast
  .highcharts-color-51 {
    fill: var(--emerald-300);
  }

  .highcharts-data-label-color-51 .pieLabelStyle {
    color: text-color($emerald-300) !important;
  }

  //West
  .highcharts-color-52 {
    fill: var(--sky-500);
  }

  .highcharts-data-label-color-52 .pieLabelStyle {
    color: text-color($sky-500) !important;
  }

  //East
  .highcharts-color-53 {
    fill: var(--indigo-500);
  }

  .highcharts-data-label-color-53 .pieLabelStyle {
    color: text-color($indigo-500) !important;
  }

  //Mountain
  .highcharts-color-54 {
    fill: var(--blueGray-500);
  }

  .highcharts-data-label-color-54 .pieLabelStyle {
    color: text-color($blueGray-500) !important;
  }

  //South
  .highcharts-color-55 {
    fill: var(--stone-500);
  }

  .highcharts-data-label-color-55 .pieLabelStyle {
    color: text-color($stone-500) !important;
  }
}

@layer grid {
  @include ag.grid-styles(
    (
      theme: quartz,
      --ag-active-color: var(--primary-color),
      --ag-foreground-color: var(--text-color),
      --ag-header-background-color: var(--surface-b),
      --ag-header-cell-hover-background-color: var(--surface-c)
    )
  );
}

@layer adaptable {
  // Adaptable AG Grid
  :root {
    --ab-color-primary: var(--primary-color);
    --ab-color-primarydark: var(--primary-color-dark);
    --ab-color-on-primary: var(--primary-color-text);
    --ab-color-secondary: var(--primary-color);
    --ab-color-blue: var(--primary-color);
    --ab-color-text-on-secondary: var(--primary-color-text);
    --ab-color-accent: var(--primary-color);
    --ab-color-accentlight: var(--primary-color-text);
    --ab-color-text-on-defaultbackground: var(--primary-color-text);
    --ab-cmp-dialog__color: var(--text-color);

    --ab-cmp-toolpanelpanel_header__color: var(--primary-color-text);
    --ab-cmp-panel_header__color: var(--primary-color-text);
    --ab-cmp-listgroupitem__color: var(--primary-color-text);

    --ab-cmp-tooltip__opacity: 0.96;
    --ab-cmp-tooltip__background: var(--surface-b);
    --ab-cmp-tooltip__color: var(--ab-color-text-on-primary);
    --ab-cmp-tooltip__border: solid 1px var(--ab-color-inputborder);
    --ab-cmp-tooltip__font-size: var(--ab-font-size-2);

    --ab-focus__box-shadow: 0 0 3px 1px var(--ab-color-accent);
  }

  .ab-ValueSelector__tag {
    background-color: var(--surface-d) !important;
    color: var(--text-color) !important;
  }

  .ab-ValueSelector__option {
    background-color: var(--surface-d) !important;
    color: var(--text-color) !important;
  }

  .ab-SimpleButton {
    display: flex !important;
  }
}

@layer highcharts {
  .highcharts-background {
    fill: #ffffff;
  }

  .pieLabelStyle {
    font-weight: bold;
    font-size: 0.8rem;
  }
}

@layer primeng {
  .p-overlaypanel:before,
  .p-overlaypanel:after {
    display: none !important;
  }

  .p-overlaypanel-content {
    padding: 0;
  }

  .p-dialog .p-dialog-header {
    border-bottom: none;
  }

  .p-dialog .p-dialog-footer {
    border-top: none;
  }

  .p-dialog-content {
    overflow-y: hidden !important;
  }
}

@layer custom {
  html {
    height: 100%;
  }

  body {
    display: block;
    min-height: 100%;
    background: var(--surface-100);
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: #333333;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.17rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.83rem;
  }

  h6 {
    font-size: 0.67rem;
  }

  //LAYOUT
  $layout-menu-width: 225px;
  $layout-menu-collapsed-width: 80px;

  .layout {
    min-height: 100vh;

    &__menu {
      position: fixed !important;
      height: 100%;
      width: $layout-menu-width;

      top: 0;
      left: 0;

      // transition: width 0.2s;
      display: flex;
      flex-direction: column;

      border: 0 none;

      color: var(--primary-color-text);
      background-color: var(--primary-color);
    }

    &__container {
      display: flex !important;
      flex-direction: column !important;
      height: 100vh;
      margin-left: $layout-menu-width;
      // transition: margin-left 0.2s;
    }

    &--collapsed {
      .layout__menu {
        width: $layout-menu-collapsed-width;
      }

      .layout__container {
        margin-left: $layout-menu-collapsed-width;
      }
    }
  }

  .card {
    background: #fff;
    border: 1px solid var(--surface-300);
    border-radius: 8px;
  }

  .card-header {
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--surface-900);
  }

  .card-body,
  .card-footer {
    padding: 1rem 1.5rem;
  }

  .p-inputtext {
    font-size: 0.875rem;
  }
}

.recalledDrug {
  background-color: #ff730b !important;
  color: #000000 !important;
}
.expiredDrug {
  background-color: #f5503e !important;
  color: #000000 !important;
}
.restockDrug {
  background-color: #ba8146 !important;
  color: #000000 !important;
}
.returnDrug {
  background-color: #0cbfeb !important;
  color: #000000 !important;
}
.relocateDrug {
  background-color: #f9d300 !important;
  color: #000000 !important;
}
.inStockAtRisk {
  background-color: #ff2457 !important;
  color: #000000 !important;
}

