.p-card {
    background: $panelContentBg;
    color: $panelContentTextColor;
    box-shadow: $cardShadow;
    border-radius: $borderRadius;

    .p-card-body {
        padding: $cardBodyPadding;
        background-color: var(--primary-color);
        padding: 0;
    }

    .p-card-title {
        font-size: $cardTitleFontSize;
        font-weight: $cardTitleFontWeight;

        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        justify-content: center;
        padding: 0.5rem;
        color: white;
    }

    .p-card-subtitle {
        font-weight: $cardSubTitleFontWeight;
        margin-bottom: $inlineSpacing;
        color: $cardSubTitleColor;
    }

    .p-card-content {
        //padding: $cardContentPadding;
    }

    .p-card-footer {
        padding: $cardFooterPadding;
    }
}
