$primaryColor: #0355a1;
$primaryLightColor: #026bc7 !default;
$primaryDarkColor: #074985;
$primaryDarkerColor: #0c3e6e;
$primaryTextColor: #f5f5f5 !default;

$secondaryColor: #667a91;
$secondaryDarkColor: #516278;

$highlightBg: #eff6ff !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

:root {
  --blueGray-50: #e5ebed;
  --blueGray-100: #cbd6db;
  --blueGray-200: #97adb8;
  --blueGray-300: #638595;
  --blueGray-400: #2f5c72;
  --blueGray-500: #2b4f60; /* Primary color */
  --blueGray-600: #264755;
  --blueGray-700: #1f3b46;
  --blueGray-800: #192e37;
  --blueGray-900: #132127;

  --emerald-50: #e7f4f1;
  --emerald-100: #d0eae2;
  --emerald-200: #a1d5c5;
  --emerald-300: #72bfaa;
  --emerald-400: #43aa8e;
  --emerald-500: #149573;
  --emerald-600: #128563;
  --emerald-700: #0f7453;
  --emerald-800: #0c6343;
  --emerald-900: #095234;

  --green-50: #e6f4eb;
  --green-100: #cde9d7;
  --green-200: #9bd3b0;
  --green-300: #6abd89;
  --green-400: #39a763;
  --green-500: #08913d;
  --green-600: #078235;
  --green-700: #06712d;
  --green-800: #055f25;
  --green-900: #044e1d;

  --lime-50: #f3fae6;
  --lime-100: #e8f5cd;
  --lime-200: #d1eba1;
  --lime-300: #b9e075;
  --lime-400: #a2d649;
  --lime-500: #8bcc1d;
  --lime-600: #7db81a;
  --lime-700: #6ea416;
  --lime-800: #5e9013;
  --lime-900: #4f7d10;

  --red-50: #fceae9;
  --red-100: #f8d5d2;
  --red-200: #f1aba4;
  --red-300: #ea8277;
  --red-400: #e35849;
  --red-500: #dc2f1c;
  --red-600: #c62a19;
  --red-700: #af2516;
  --red-800: #991f13;
  --red-900: #821a10;

  --orange-50: #fceee6;
  --orange-100: #f9dccd;
  --orange-200: #f4b99a;
  --orange-300: #ee9767;
  --orange-400: #e97334;
  --orange-500: #e35001;
  --orange-600: #cc4901;
  --orange-700: #b44301;
  --orange-800: #9c3c01;
  --orange-900: #843401;

  --amber-50: #fff9e6;
  --amber-100: #fff2cc;
  --amber-200: #ffe599;
  --amber-300: #ffd766;
  --amber-400: #ffca33;
  --amber-500: #ffbc00;
  --amber-600: #e6a900;
  --amber-700: #cc9500;
  --amber-800: #b38200;
  --amber-900: #997000;

  --yellow-50: #fefce6;
  --yellow-100: #fef8cc;
  --yellow-200: #fdf099;
  --yellow-300: #fce766;
  --yellow-400: #fadd33;
  --yellow-500: #f9d300;
  --yellow-600: #e0be00;
  --yellow-700: #c8a900;
  --yellow-800: #b09300;
  --yellow-900: #987e00;

  --teal-50: #e6f4f6;
  --teal-100: #cde9ed;
  --teal-200: #9bd3da;
  --teal-300: #6abdca;
  --teal-400: #39a7b9;
  --teal-500: #0891a8;
  --teal-600: #07829a;
  --teal-700: #06718b;
  --teal-800: #055f7c;
  --teal-900: #044e6d;

  --cyan-50: #e6f8fa;
  --cyan-100: #cdeff5;
  --cyan-200: #9bdfe9;
  --cyan-300: #6accdd;
  --cyan-400: #39bcd2;
  --cyan-500: #08abc6;
  --cyan-600: #079ab2;
  --cyan-700: #06899e;
  --cyan-800: #05788a;
  --cyan-900: #046676;

  --sky-50: #e6f6fb;
  --sky-100: #ccedf7;
  --sky-200: #99dcef;
  --sky-300: #66cae7;
  --sky-400: #33b8df;
  --sky-500: #00a6d7;
  --sky-600: #0096c1;
  --sky-700: #0086aa;
  --sky-800: #007594;
  --sky-900: #00647d;

  --indigo-50: #ebeaf6;
  --indigo-100: #d7d5ed;
  --indigo-200: #afaace;
  --indigo-300: #8780af;
  --indigo-400: #5e5590;
  --indigo-500: #363b71;
  --indigo-600: #313563;
  --indigo-700: #2a2e54;
  --indigo-800: #232746;
  --indigo-900: #1c1f38;

  --violet-50: #f4e6fa;
  --violet-100: #e9ccf5;
  --violet-200: #d399ec;
  --violet-300: #bc66e3;
  --violet-400: #a633da;
  --violet-500: #8f00d1;
  --violet-600: #8000bc;
  --violet-700: #7100a7;
  --violet-800: #620091;
  --violet-900: #53007c;

  --purple-50: #f5e6f9;
  --purple-100: #ebccf3;
  --purple-200: #d799e6;
  --purple-300: #c266d9;
  --purple-400: #ae33cc;
  --purple-500: #9900bf;
  --purple-600: #8900aa;
  --purple-700: #780094;
  --purple-800: #67007f;
  --purple-900: #560069;

  --fuchsia-50: #f9e6f9;
  --fuchsia-100: #f2ccf3;
  --fuchsia-200: #e699e6;
  --fuchsia-300: #d966d9;
  --fuchsia-400: #cc33cc;
  --fuchsia-500: #bf00bf;
  --fuchsia-600: #aa00aa;
  --fuchsia-700: #940094;
  --fuchsia-800: #7f007f;
  --fuchsia-900: #690069;

  --pink-50: #fde6f2;
  --pink-100: #fbcced;
  --pink-200: #f699db;
  --pink-300: #f066c8;
  --pink-400: #eb33b6;
  --pink-500: #e600a3;
  --pink-600: #cf0093;
  --pink-700: #b80083;
  --pink-800: #a20073;
  --pink-900: #8b0063;

  --rose-50: #fde6ec;
  --rose-100: #fbcdd9;
  --rose-200: #f799b3;
  --rose-300: #f2668c;
  --rose-400: #ee3366;
  --rose-500: #ea003f;
  --rose-600: #d30039;
  --rose-700: #bc0033;
  --rose-800: #a5002d;
  --rose-900: #8e0027;

  --slate-50: #f1f5f9;
  --slate-100: #e2e8f0;
  --slate-200: #cbd5e1;
  --slate-300: #94a3b8;
  --slate-400: #64748b;
  --slate-500: #475569;
  --slate-600: #334155;
  --slate-700: #1e293b;
  --slate-800: #0f172a;
  --slate-900: #0a1123;

  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;

  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;

  --neutral-50: #fafafa;
  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-800: #262626;
  --neutral-900: #171717;

  --stone-50: #fafaf9;
  --stone-100: #f5f5f4;
  --stone-200: #e7e5e4;
  --stone-300: #d6d3d1;
  --stone-400: #a8a29e;
  --stone-500: #78716c;
  --stone-600: #57534e;
  --stone-700: #44403c;
  --stone-800: #292524;
  --stone-900: #1c1917;

  --brown-50: #f3ebe8;
  --brown-100: #e6d7d1;
  --brown-200: #cdb0a3;
  --brown-300: #b58976;
  --brown-400: #9c6248;
  --brown-500: #834b32;
  --brown-600: #76432d;
  --brown-700: #663924;
  --brown-800: #552f1c;
  --brown-900: #442514;
}

@import './variables';
@import './_fonts';
@import '../../theme-base/_components';
@import './_extensions';

